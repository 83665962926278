export default [
  {
    title: 'complex',
    route: { name: 'phases.complex' },
    icon: 'buildings',
    showCount: true,
    getterName: 'phase/complexTotal'
  },
  {
    title: 'Media',
    route: { name: 'phases.media' },
    icon: 'gallery',
  },
  {
    title: '3D view',
    route: { name: 'phases.ThreeDView' },
    icon: '3dcube',
  },
  {
    title: 'Required Form',
    route: { name: 'phases.requiredForm' },
    icon: 'folder',
  },
  {
    title: 'Property',
    route: { name: 'phases.property' },
    icon: 'propertyIcon-2',
  }
]
