<template>
  <div class="full-height">
    <LayoutVerticalCustom
      :verticalNavbarList="verticalNavbarList"
      :name="phaseDetails ? phaseDetails.name : ''"
      :thumbnail="phaseDetails ? phaseDetails.thumbnail : ''"
      @openEditSidebar="openEditSidebar"
    />

    <div>
      <b-sidebar
        id="edit-project-sidebar-id"
        no-header
        bg-variant="white"
        shadow right lazy backdrop
        :backdrop-variant="'dark'"
      >
        <template #default="{ hide }">
          <div class="fd-flex fd-items-center fd-justify-between fd-p-3" style="background-color: #E9EDEB;">
            <span class="fd-text-theme-2 fd-text-base">{{ $t('global.setting') }}</span>
            <div @click="openEditSidebar" class="fd-cursor-pointer">
              <SvgLoader :name="'close'" />
            </div>

          </div>
          <div class="fd-p-3">
            <div class="project-image fd-mx-auto fd-overflow-hidden">
              <img :src="getNewImageUrl ? getNewImageUrl : phaseDetails.thumbnail" alt="" class="object-cover" />
            </div>
            <span class="fd-text-xs fd-text-theme-3 fd-block fd-text-center fd-mt-2">{{ $t('global.allowedFileTypes') }}</span>
            <div class="fd-flex fd-items-center fd-justify-center fd-mt-5 fd-mx-auto" style="width: 231px;">
              <input type="file" ref="file" @change="uploadFile" style="display: none">
              <b-button block variant="primary" class="fd-mr-2" @click="upload">{{ $t('global.upload') }}</b-button>
              <b-button block variant="outline-primary" @click="resetFile" style="margin-top: 0 !important;">{{ $t('global.reset') }}</b-button>
            </div>
            <div class="fd-mt-14">
              <div>
                <label for="name" class="fd-text-xs fd-text-theme-9">{{ $t('global.name') }}</label>
                <b-form-input id="name" v-model="form.name" type="text" />
              </div>
              <div class="fd-mt-3 fd-text-theme-9">
                <label for="description">{{ $t('global.description') }}</label>
                <b-form-textarea id="description" v-model="form.description" rows="10" max-rows="10" style="font-size: 0.75rem !important; line-height: 1rem; !important;" />
              </div>
              <div class="fd-mt-3 fd-text-theme-9">
                <label for="deliveryTime">{{ $t('global.deliveryDate') }}</label>
                <Datepicker
                  class="datepicker"
                  id="deliveryTime"
                  v-model="form.delivery_date"
                  format="yyyy-MM-dd"
                  :clear-button="true"
                />
              </div>
              <div class="fd-flex fd-items-center fd-justify-center fd-mt-5">
                <b-button @click="update" block variant="primary" class="fd-mr-2">{{ $t('global.save') }}</b-button>
                <b-button @click="openEditSidebar" block variant="outline-danger" style="margin-top: 0 !important;">{{ $t('global.cancel') }}</b-button>
              </div>
            </div>
          </div>
        </template>

      </b-sidebar>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/views/components/global/Sidebar";
import verticalNavbar from "@/views/components/ProjectManagement/verticalPhaseNavbar";
import { mapGetters } from 'vuex'
import { BSidebar, BButton, BFormInput, BFormTextarea, BBreadcrumb } from 'bootstrap-vue'
import LayoutVerticalCustom from "@/layouts/vertical/LayoutVerticalCustom";
import TextEditor from "@/views/components/global/TextEditor";
import Datepicker from 'vuejs-datepicker';

export default {
  name: "Phase",
  components: {TextEditor, LayoutVerticalCustom, Sidebar, BSidebar, BButton, BFormInput, BFormTextarea, BBreadcrumb, Datepicker},
  data() {
    return {
      verticalNavbarList: [],
      image: null,
      newImageUrl: null,
      thumbnail: null,
      form: {
        name: '',
        delivery_date: '',
        description: ''
      }
    }
  },
  created() {
    this.getPhaseDetails()
    this.verticalNavbarList = verticalNavbar
  },
  computed: {
    ...mapGetters({
      phaseDetails: 'phase/phaseDetails'
    }),
    getNewImageUrl() {
      return this.newImageUrl
    }
  },
  methods: {
    openEditSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'edit-project-sidebar-id')
    },
    getPhaseDetails() {
      this.$store.dispatch('phase/getPhaseDetails', this.$route.params.id).then(() => {
        this.form.name = this.phaseDetails.name
        this.form.delivery_date = this.phaseDetails.delivery_date
        this.form.description = this.phaseDetails.description
        this.thumbnail = this.phaseDetails.thumbnail
      })
    },
    update() {
      let formData = new FormData()

      if (this.image) {
        formData.append('thumbnail', this.image)
      }
      Object.keys(this.form).forEach(key => {
        if (key === 'delivery_date') {
          if (!this.form[key]) return
          let date = new Date(this.form[key])
          // YYYY-MM-DD
          date = date.getFullYear() + '-' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))
          formData.append(key, date.toString())
        } else {
          formData.append(key, this.form[key] ? this.form[key] : '')
        }

      })

      this.$store.dispatch('phase/updatePhase', {
        id: this.$route.params.id,
        data: formData
      })
        .then(() => {
          this.getPhaseDetails()
          this.openEditSidebar()
        })
    },
    upload() {
      this.$refs.file.click()
    },
    uploadFile() {
      this.image = this.$refs.file.files[0]
      this.newImageUrl = URL.createObjectURL(this.image)
    },
    resetFile() {
      this.image = null
      this.newImageUrl = null
    },
  }
}
</script>

<style lang="scss" scoped>

.project-image {
  width: 231px;
  height: 174px;
  border-radius: 3px;
  img {
    width: 231px;
    height: 174px;
  }
}
.datepicker {
  ::v-deep input {
    width: 100%;
    border: 1px solid #d8d6de;
    padding: .6rem 1rem;
    border-radius: 8px;
  }
}
</style>

<style lang="scss">
.vdp-datepicker__clear-button {
  position: absolute !important;
  right: 10px !important;
  top: 2px !important;
  font-size: 26px !important;
}
</style>